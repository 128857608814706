import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Space } from 'antd';
import { Image } from 'antd';
import profilePicture from "../resources/media/avatar.jpeg";
import resumeFile from "../resources/resume/CV_Tomas_Canavilhas.pdf";
import DownloadButton from "../components/APIs/DownloadButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Col, Row } from 'antd';
import { useTheme } from '../components/ThemeContext';

const WhoAmI = () => {
    const { darkMode, onChange } = useTheme(); // Use the context

    return (
        <Row justify="space-around" align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="pt-8 px-8">
            <Col sm={16} md={8} className="gutter-row" style={{ display: 'flex', justifyContent: 'center' }}>
                <Image src={profilePicture} alt='Profile' className='profile-picture object-cover' />
            </Col>
            <Col sm={32} md={16} className="gutter-row sm:scale-100 scale-75 " style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className={`profile-container ${darkMode ? 'dark' : ''}`}>
                    <h1 className={`pt-8 name ${darkMode ? 'dark' : ''}`}>
                        Tomás Canavilhas
                        <span className={'icon'}>
                            <a href="https://www.linkedin.com/in/tomascanavilhas/" target="_blank"
                                rel="noopener noreferrer" className={`linkedin-icon ${darkMode ? 'dark' : ''}`}>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </span>
                    </h1>
                </div>
                <p className={`description ${darkMode ? 'dark' : ''}`}>Experienced software engineer currently
                    working in a Java Project.
                    Skilled in developing backend & frontend
                    software solutions, troubleshooting, and debugging. Proven ability to create and implement
                    software solutions to meet customer
                    requirements.</p>
                <DownloadButton url={resumeFile} darkMode={darkMode} />
            </Col>
        </Row>
    );

};

export default WhoAmI;
import React, { useEffect, useState } from 'react';

const Countdown = ({ futureDate }) => {
  const calculateTimeLeft = () => {
    const difference = futureDate - new Date();

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [futureDate]);

  return (
    <div className="flex flex-col items-center justify-center h-full text-5xl text-red-500">
      <div className="flex">
        <div className="font-bold">{timeLeft.days}d</div>
        <div className="mx-1">:</div>
        <div className="font-bold">{timeLeft.hours}h</div>
        <div className="mx-1">:</div>
        <div className="font-bold">{timeLeft.minutes}m</div>
        <div className="mx-1">:</div>
        <div className="font-bold">{timeLeft.seconds}s</div>
      </div>
      <div className="text-xs uppercase">Countdown</div>
    </div>
  );
};

export default Countdown;

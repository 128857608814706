import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Input, Checkbox, notification, Alert, QRCode, Tag, Space, Tooltip } from 'antd';
import { PlusOutlined, SmileOutlined } from '@ant-design/icons';
import "../resources/css/LinkShortener.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPersonWalkingArrowLoopLeft, faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import Countdown from "../components/Countdown";

const LinkShortener = () => {
    const [url, setUrl] = useState('');
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const [deadline, setDeadline] = useState(null);
    const [isKeywordEnabled, setIsKeywordEnabled] = useState(false); // Correctly declare state variable here
    const [api, contextHolder] = notification.useNotification();
    const [shortenedLink, setShortenedLink] = useState('');
    const [tooltipTitle, setTooltipTitle] = useState('Click to copy link');

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(shortenedLink).then(() => {
            setTooltipTitle('Link copied to clipboard!'); // Update tooltip text after copying
            setTimeout(() => setTooltipTitle('Click to copy link'), 2000); // Reset tooltip text after 2 seconds
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    const handleShortenClick = async () => {
        try {
            if (!url) {
                notification.warning({
                    message: 'URL Required',
                    description: 'Please enter a URL to shorten.',
                    icon: <SmileOutlined style={{ color: 'orange' }} />,
                });
                return;
            }
            if (isKeywordEnabled && tags.length === 0) {
                notification.warning({
                    message: 'Keywords Required',
                    description: 'Please enter at least one keyword.',
                    icon: <SmileOutlined style={{ color: 'orange' }} />,
                });
                return;
            }
            // if is not a valid URL
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                notification.warning({
                    message: 'Invalid URL',
                    description: 'Please enter a valid URL starting with http:// or https://.',
                    icon: <SmileOutlined style={{ color: 'orange' }} />,
                });
                return;
            }
            // if is not a valid URL, dont have a . something
            if (!url.includes('.')) {
                notification.warning({
                    message: 'Invalid URL',
                    description: 'Please enter a valid URL.',
                    icon: <SmileOutlined style={{ color: 'orange' }} />,
                });
                return;
            }
            // Ensure your backend endpoint matches your environment
            const response = await axios.post('https://tomascanavilhas.tech:8080/api/shorten', { url, customAlias: tags.join(',') });
            setShortenedLink(response.data.shortUrl);
            setDeadline(Date.now() + 1000 * 60 * 60 * 48); // Set for 48 hours from now
            notification.success({
                message: 'Shortened URL Created Successfully',
                description: 'Your shortened URL is ready to use and will expire in 48 hours.',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });
        } catch (error) {
            notification.error({
                message: 'Error Creating Shortened URL',
                description: error.response?.data || 'An unexpected error occurred.',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    };

    const handleClose = (removedTag) => {
        const newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !tags.includes(inputValue)) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const navigate = useNavigate(); // Using useNavigate hook for navigation

    const onFinishCountdown = () => {
        notification.info({
            message: 'Link Expired',
            description: 'The shortened link has expired.',
            icon: <SmileOutlined style={{ color: 'orange' }} />,
        });
        setShortenedLink('');
    };

    const downloadQRCode = () => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = 'QRCode.png';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    return (
        <>
            {contextHolder}
            <button
                onClick={() => navigate(-1)} // Updated method for going back
                className="flex flex-col items-center px-4 py-2 text-gray-800 rounded transition duration-300 ease-in-out mt-4 mb-8"
            >
                <FontAwesomeIcon style={{ color: "#fff", scale: "1.5" }} icon={faPersonWalkingArrowLoopLeft} />
                <span className="go-back ml-2 text-white">Go Back</span> {/* Optional text */}
            </button>
            <div className="flex flex-col items-center justify-center p-4">
                <h2 className='link-shortener-title'>L<FontAwesomeIcon icon={faLink} />NK SHORTENER</h2>
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                    <Input
                        placeholder="Enter URL here"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        className="mb-4"
                    />
                    <Checkbox
                        checked={isKeywordEnabled}
                        onChange={(e) => setIsKeywordEnabled(e.target.checked)}
                        className="mb-4"
                    >
                        Enable Keywords
                    </Checkbox>
                    {isKeywordEnabled && (
                        <Space wrap className="mb-4">
                            {tags.map((tag, index) => (
                                <Tag
                                    key={tag}
                                    closable={true}
                                    onClose={() => handleClose(tag)}
                                >
                                    {tag}
                                </Tag>
                            ))}
                            {inputVisible && (
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="small"
                                    className="tag-input"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                />
                            )}
                            {!inputVisible && (
                                <Tag className="site-tag-plus" onClick={showInput}>
                                    <PlusOutlined /> New Tag
                                </Tag>
                            )}
                        </Space>
                    )}
                    <Alert
                        message="Why Choose Keywords?"
                        description="Choosing keywords allows you to personalize the shortened link, making it easier to remember and share. "
                        type="info"
                        showIcon
                    />

                    <Button type="primary" onClick={handleShortenClick} className="my-4 bg-black w-fit mb-4">
                        Shorten Link <FontAwesomeIcon icon={faLink} className='ml-2' />
                    </Button>
                    {shortenedLink && (
                        <div className='w-full'>
                            <Space.Compact className='w-full'>
                                <Input defaultValue={shortenedLink} className='flex-grow' readOnly />
                                <Tooltip title={tooltipTitle}>
                                    <Button className='bg-blue-600' onClick={handleCopyToClipboard}>
                                        <FontAwesomeIcon icon={faCopy} color='white' />
                                    </Button>
                                </Tooltip>
                            </Space.Compact>
                            <div id="myqrcode" className='flex flex-col items-center justify-center align-center'>
                                <QRCode value={shortenedLink} size={9999} className='w-50 h-50 my-10' />
                                <Button type="primary" onClick={downloadQRCode} className="bg-black mb-4 text-white w-fit">Download QR Code</Button>
                                {deadline && (
                                    <Countdown futureDate={deadline} onFinish={onFinishCountdown} />
                                )}
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </>
    );
};

export default LinkShortener;

import React from 'react';
import { useTheme } from './ThemeContext'; // Correct path as needed
import '../resources/css/Background.css'; // Ensure this path is correct

const Background = () => {
    const { darkMode } = useTheme(); // Use darkMode

    return (
        <div className={`dynamic-diagonal-background ${darkMode ? 'dark' : 'light'}`}></div>
    );
};

export default Background;

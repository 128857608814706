import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { TinyColor } from '@ctrl/tinycolor';
import { Button, Tooltip, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPersonWalkingArrowLoopLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SmileOutlined } from '@ant-design/icons';


const PasswordGenerator = () => {
    const [passwordLength, setPasswordLength] = useState(8);
    const [includeNumbers, setIncludeNumbers] = useState(true);
    const [includeLowercase, setIncludeLowercase] = useState(true);
    const [includeUppercase, setIncludeUppercase] = useState(true);
    const [beginWithLetter, setBeginWithLetter] = useState(true);
    const [includeSymbols, setIncludeSymbols] = useState(true);
    const [noSimilarCharacters, setNoSimilarCharacters] = useState(true);
    const [noDuplicateCharacters, setNoDuplicateCharacters] = useState(true);
    const [noSequentialCharacters, setNoSequentialCharacters] = useState(true);
    const [autoGenerate, setAutoGenerate] = useState(true);
    const [quantity, setQuantity] = useState(5);
    const [savePreference, setSavePreference] = useState(false);
    const [passwords, setPasswords] = useState([]);
    const togglePreference = () => {
        setSavePreference(!savePreference); // Toggle the current state
    };
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate(); 

    useEffect(() => {
        const preferences = Cookies.get('passwordGeneratorPreferences');
        if (preferences) {
            const loadedPreferences = JSON.parse(preferences);
            setPasswordLength(loadedPreferences.passwordLength);
            setIncludeNumbers(loadedPreferences.includeNumbers);
            setIncludeLowercase(loadedPreferences.includeLowercase);
            setIncludeUppercase(loadedPreferences.includeUppercase);
            setBeginWithLetter(loadedPreferences.beginWithLetter);
            setIncludeSymbols(loadedPreferences.includeSymbols);
            setNoSimilarCharacters(loadedPreferences.noSimilarCharacters);
            setNoDuplicateCharacters(loadedPreferences.noDuplicateCharacters);
            setNoSequentialCharacters(loadedPreferences.noSequentialCharacters);
            setAutoGenerate(loadedPreferences.autoGenerate);
            setQuantity(loadedPreferences.quantity);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (savePreference) {
            // Save preferences as a JSON string in cookies
            const preferences = {
                passwordLength,
                includeNumbers,
                includeSymbols,
                noSequentialCharacters,
                savePreference,
                includeLowercase,
                noSimilarCharacters,
                beginWithLetter,
                includeUppercase,
                noDuplicateCharacters,
                autoGenerate,
                quantity
            };
            Cookies.set('passwordGeneratorPreferences', JSON.stringify(preferences));
            notification.success({
                message: 'Preferences Saved',
                description: 'Your password generation preferences have been saved successfully.',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />
            });
        }

        const generatedPasswords = Array.from({ length: quantity }, generatePassword);
        console.log(generatedPasswords); // Confirming the generated passwords before setting state
        setPasswords(generatedPasswords);
        notification.success({
            message: 'Passwords Generated',
            description: 'Passwords have been generated successfully.',
            icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        });
    };

    const generatePassword = () => {
        // Define character sets
        const numbers = '0123456789';
        const lowerLetters = 'abcdefghijklmnopqrstuvwxyz';
        const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const symbols = '!@#$%^&*()_+-=[]{}|;:",.<>?';

        let characterPool = '';
        let password = '';

        // Conditionally add character sets based on user preferences
        if (includeNumbers) {
            characterPool += numbers;
        }
        if (includeLowercase) {
            characterPool += lowerLetters;
        }
        if (includeUppercase) {
            characterPool += upperLetters;
        }
        if (includeSymbols) {
            characterPool += symbols;
        }

        // Function to get a random character from a set
        const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];

        // Ensure the password starts with a letter if required
        if (beginWithLetter) {
            password += getRandomChar(includeLowercase ? lowerLetters : upperLetters);
        }

        // Fill the rest of the password length
        for (let i = password.length; i < passwordLength; i++) {
            let randomChar = getRandomChar(characterPool);

            if (noDuplicateCharacters) {
                while (password.includes(randomChar)) {
                    randomChar = getRandomChar(characterPool);
                }
            }

            if (noSequentialCharacters && i > 0) {
                const lastIndex = characterPool.indexOf(password[i - 1]);
                // Check if the new character is sequential to the last character
                while (Math.abs(characterPool.indexOf(randomChar) - lastIndex) === 1) {
                    randomChar = getRandomChar(characterPool);
                }
            }

            if (noSimilarCharacters) {
                const similarChars = 'il1Lo0O';
                while (similarChars.includes(randomChar)) {
                    randomChar = getRandomChar(characterPool);
                }
            }

            password += randomChar;
        }

        return password;
    };

    return (
        <>
            {contextHolder}
            <button
                onClick={() => navigate(-1)} // Updated method for going back
                className="flex flex-col items-center px-4 py-2 text-gray-800 rounded transition duration-300 ease-in-out mt-4 mb-8"
            >
                <FontAwesomeIcon style={{ color: "#fff", scale: "1.5" }} icon={faPersonWalkingArrowLoopLeft} />
                <span className="go-back ml-2 text-white">Go Back</span> {/* Optional text */}
            </button>
            <div className="bg-white shadow-lg rounded-lg p-6 m-4 max-w-4xl mx-auto">
                <h1 className="text-2xl font-bold text-gray-800 mb-4">Password Generator</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                            <label className="font-semibold">
                                Password Length:
                                <input
                                    type="number"
                                    value={passwordLength}
                                    onChange={(e) => setPasswordLength(e.target.value)}
                                    className="mt-1 p-2 border rounded-md"
                                />
                            </label>
                        </div>
                        <div className="flex flex-col">
                            <label className="font-semibold">
                                Quantity:
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    className="mt-1 p-2 border rounded-md"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={includeNumbers}
                                onChange={(e) => setIncludeNumbers(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Include Numbers</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={includeLowercase}
                                onChange={(e) => setIncludeLowercase(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Include Lowercase Characters</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={includeUppercase}
                                onChange={(e) => setIncludeUppercase(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Include Uppercase Characters</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={includeSymbols}
                                onChange={(e) => setIncludeSymbols(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Include Symbols</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={noSimilarCharacters}
                                onChange={(e) => setNoSimilarCharacters(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">No Similar Characters</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={noDuplicateCharacters}
                                onChange={(e) => setNoDuplicateCharacters(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">No Duplicate Characters</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={noSequentialCharacters}
                                onChange={(e) => setNoSequentialCharacters(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">No Sequential Characters</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={beginWithLetter}
                                onChange={(e) => setBeginWithLetter(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Begin With A Letter</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                checked={autoGenerate}
                                onChange={(e) => setAutoGenerate(e.target.checked)}
                                className="mr-2 h-5 w-5"
                            />
                            <label className="font-semibold">Auto Generate On The First Call</label>
                        </div>
                        <div className="flex items-center">
                            <Tooltip title="Save My Preferences">
                                <Button
                                    shape="circle"
                                    className={`${savePreference ? 'bg-gradient-to-r from-blue-400 to-cyan-500' : 'bg-gray-400'}`}
                                    icon={<FontAwesomeIcon icon={faFloppyDisk} color="white" />}
                                    onClick={togglePreference}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
                        Generate Passwords
                    </button>
                </form>
                {passwords.length > 0 && (
                    <div className="mt-4 p-4 bg-gray-100 rounded-md shadow">
                        <h2 className="text-lg font-semibold">Generated Passwords</h2>
                        <ul className="list-disc pl-5">
                            {passwords.map((password, index) => (
                                <li key={index} className="font-mono text-gray-700">{password}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default PasswordGenerator;
import '../resources/css/AboutMe.css';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Space } from 'antd';
import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import '../resources/css/About.css';
import profilePicture from "../resources/media/avatar.jpeg";
import resumeFile from "../resources/resume/CV_Tomas_Canavilhas.pdf";
import DownloadButton from "../components/APIs/DownloadButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Projects from './Projects';
import { Col, Row } from 'antd';
import { useTheme } from '../components/ThemeContext';
import Skills from './Skills';
import WhoAmI from './WhoAmI';

const AboutMe = () => {
    const { darkMode, onChange } = useTheme(); // Use the context
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleButtonClick = (content) => {
        setModalContent(content);
        setModalShow(true);
    }

    return (
        <>
            <WhoAmI />
            <Skills />
            <Projects />
        </>
    );
};

export default AboutMe;
import React, {useEffect, useState} from 'react';
import '../resources/css/About.css';
import profilePicture from "../resources/media/avatar.jpeg";
import {Button, Modal} from "react-bootstrap";
import resumeFile from "../resources/resume/CV_Tomas_Canavilhas.pdf";
import '../resources/css/Projects.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareUpRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTheme} from '../components/ThemeContext';

const projects = [
    {
        name: 'Link Shortener',
        link: '/linkshortener',
    },
    {
        name: 'Password Generator',
        link: '/passwordgenerator',
    },
    {
        name: 'Memory Game',
        link: '/memorygame',
    },
];

const publicProjects = [
    {
        name: 'Wedding App',
        link: 'http://franciscaepedro2024.pt/',
        description: 'A wedding website where grooms can both manage guests and gifts. Guests can also RSVP and offer gifts. Password protected.', 
    }
];

const Projects = () => {
    const { darkMode, onChange } = useTheme(); // Use the context
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleRunProject = (project) => {
        // Here you would clone, build, and run the project.
        // The implementation will depend on your specific setup.
        console.log(`Running project: ${project.name}`);
    };

    const handleButtonClick = (content) => {
        setModalContent(content);
        setModalShow(true);
    }

    return (
        <div className={"w-screen p-12"}>
            <div className={`col projects  ${darkMode ? 'dark bg-gradient-to-r from-blue-800 to-indigo-900' : 'bg-gradient-to-r from-blue-200 to-cyan-200'}`}>
                <h2 className={`challenges ${darkMode ? 'dark' : ''}`}>Skills Showcase</h2>
                {projects.map((project) => (
                    <div className={`project ${darkMode ? 'dark' : ''}`}>
                        <div>
                            <h2>{project.name}</h2>
                        </div>
                        <Link to={project.link} className="menu-link">
                            <button onClick={() => handleRunProject(project)}><FontAwesomeIcon
                                className={'square-up-right'} icon={faSquareUpRight}/></button>
                        </Link>
                    </div>
                ))}
            </div>
            <div className={`mt-4 col projects  ${darkMode ? 'dark bg-gradient-to-r from-blue-800 to-indigo-900' : 'bg-gradient-to-r from-blue-200 to-cyan-200'}`}>
                <h2 className={`challenges ${darkMode ? 'dark' : ''}`}>Public Projects</h2>
                {publicProjects.map((project) => (
                    <div className={`project ${darkMode ? 'dark' : ''}`}>
                        <div>
                            <h2>{project.name}</h2>
                            <p>{project.description}</p>
                        </div>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className={'square-up-right'} icon={faSquareUpRight}/>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
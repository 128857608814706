import React, { createContext, useContext, useState } from 'react';
import '../resources/css/Background.css';

const ThemeContext = createContext(); // Create a context object

export const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(true); // Default to true or false based on your preference

    const onChange = (checked) => {
        setDarkMode(checked);
    };

    // Make sure to provide the context value correctly
    return (
        <ThemeContext.Provider value={{ darkMode, onChange }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);

import React, { useState, useEffect } from 'react';
import '../resources/css/MemoryGame.css';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowLoopLeft, faTrophy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import Modal from 'react-modal';
import axios from 'axios';

function importAll(r) {
    return r.keys().map(key => ({ src: r(key).default, name: key }));
}

const images = importAll(require.context('../resources/media/flashcards/', false, /\.(png|jpg|svg)$/));

const MemoryGame = () => {
    const [cards, setCards] = useState([]);
    const [turns, setTurns] = useState(0);
    const [choiceOne, setChoiceOne] = useState(null);
    const [choiceTwo, setChoiceTwo] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [playerName, setPlayerName] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [showLeaderboard, setShowLeaderboard] = useState(false);

    const toggleLeaderboard = () => setShowLeaderboard(!showLeaderboard);

    useEffect(() => {
        fetchLeaderboard();
    }, []);



    const shuffleCards = () => {
        setGameOver(false);
        resetTurn();
        cards.forEach(card => card.matched = false); // Reset all cards to unmatched
        const shuffledCards = [...images, ...images]
            .sort(() => 0.5 - Math.random())
            .map((card, index) => ({ ...card, id: index, matched: false }));

        setCards(shuffledCards);
        setTurns(0);
    };

    const handleChoice = (card) => {
        // Prevent any action if the game is currently disabled (i.e., waiting on a timeout reset)
        if (disabled) {
            notification.info({
                message: 'Memory Game',
                description: 'Please wait for the cards to flip back over.',
                icon: <SmileOutlined style={{ color: 'orange' }} />,
            });
        }

        // If choiceOne is not set, or both choices are made (waiting to be reset), allow setting the first choice
        if (!choiceOne || (choiceOne && choiceTwo)) {
            setChoiceOne(card);
            setDisabled(false); // Ensure interactions are enabled if the first card is being set
        } else if (card.id !== choiceOne.id) {
            // If the clicked card is not the same as the first card, set it as choiceTwo
            setChoiceTwo(card);
            setDisabled(true); // Disable further interactions until the cards are checked
        }
    };

    useEffect(() => {
        // if is first time render, shuffle the cards
        if (cards.length === 0) {
            shuffleCards();
        } else {
            if (choiceOne && choiceTwo) {
                // Only proceed if both choices are made and they are not the same card
                setDisabled(true); // Disable new choices while processing
                if (choiceOne.name === choiceTwo.name) {
                    // If a match is found, mark both cards
                    setCards(prevCards => {
                        return prevCards.map(card => {
                            if (card.name === choiceOne.name) {
                                return { ...card, matched: true };
                            }
                            return card;
                        });
                    });
                    resetTurn();
                } else {
                    // No match, reset after a delay
                    setTimeout(() => resetTurn(), 1000);
                }
            }
            if (cards.every(card => card.matched)) {
                setGameOver(true);
                setShowModal(true);
            }
        }
    }, [choiceOne, choiceTwo]);

    const resetTurn = () => {
        // Reset both choices and enable new choices
        setChoiceOne(null);
        setChoiceTwo(null);
        setTurns(prevTurns => prevTurns + 1);
        setDisabled(false);
    };

    const navigate = useNavigate(); // Using useNavigate hook for navigation

    Modal.setAppElement('#root');

    // Function to handle adding the player to the leaderboard
    const addToLeaderBoard = async () => {
        if (playerName.trim() === '') {
            notification.error({
                message: 'Validation Error',
                description: 'Please enter a valid name.',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
            return;
        }

        try {
            // Replace with your actual backend endpoint
            const response = await axios.post('https://tomascanavilhas.tech:8080/api/leaderboard', {
                username: playerName,
                score: turns
            });

            // Update the local state if needed, or rely on fetching updated data
            setLeaderboard([...leaderboard, { username: playerName, score: turns }]);

            // Optionally close the modal
            setShowModal(false);

            // Success notification
            notification.success({
                message: 'Leaderboard Updated',
                description: 'Your score has been successfully added to the leaderboard.',
                icon: <SmileOutlined style={{ color: '#108ee9' }} />,
            });

            if (response.status === 201) {
                await fetchLeaderboard(); // Refresh the leaderboard
                setShowLeaderboard(true); // Open leaderboard
            }
        } catch (error) {
            // Error handling
            notification.error({
                message: 'Error Updating Leaderboard',
                description: error.response?.data || 'An unexpected error occurred.',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    };

    // Add leaderboard fetch function
    const fetchLeaderboard = async () => {
        try {
            const response = await axios.get('https://tomascanavilhas.tech:8080/api/leaderboard');
            setLeaderboard(response.data.slice(0, 10)); // Only store top 10
        } catch (error) {
            notification.error({
                message: 'Error Fetching Leaderboard',
                description: 'Unable to fetch leaderboard data.',
                icon: <SmileOutlined style={{ color: 'red' }} />,
            });
        }
    };

    const getTrophyColor = (index) => {
        switch (index) {
            case 0: return "text-yellow-500"; // Gold
            case 1: return "text-gray-300"; // Silver
            case 2: return "text-yellow-800"; // Bronze
        }
    };

    // Sort the leaderboard by score in ascending order
    const sortedLeaderboard = leaderboard.sort((a, b) => a.score - b.score);

    return (
        <>
            {contextHolder}
            {showLeaderboard && (
                <Modal
                    isOpen={showLeaderboard}
                    onRequestClose={() => setShowLeaderboard(false)}
                    className="fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
                >
                    <div className="relative top-20 mx-auto p-5 border w-full max-w-lg shadow-lg rounded-md bg-white">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold text-gray-900">Leaderboard</h2>
                            <button onClick={() => setShowLeaderboard(false)} className="text-gray-600 hover:text-gray-900">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <div className="mt-4">
                            {sortedLeaderboard.map((entry, index) => (
                                <div key={index} className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
                                    <span className={`flex items-center ${index < 3 ? 'font-bold' : 'font-medium'}`}>
                                        {index < 3 && <FontAwesomeIcon icon={faTrophy} className={`${getTrophyColor(index)} mr-2`} />}
                                        <span className="text-sm text-gray-700">{entry.username}</span>
                                    </span>
                                    <span className="text-sm font-semibold text-blue-600">{entry.score}</span>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4 text-right">
                            <button onClick={() => setShowLeaderboard(false)} className="btn btn-gray">
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {gameOver && (
                <>
                    <Confetti />
                    <Modal
                        isOpen={showModal}
                        onRequestClose={() => setShowModal(false)}
                        className="relative mx-auto p-5 border-0 w-full md:w-2/3 lg:w-1/2 max-w-3xl bg-white rounded-lg shadow-xl"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.65)',
                                zIndex: 50,
                                display: 'flex',
                                alignItems: 'center',  // Vertical centering
                                justifyContent: 'center'  // Horizontal centering
                            },
                            content: {
                                border: 'none',
                                background: 'none',
                                padding: 0,
                                inset: 0,
                                width: '100%',
                                maxWidth: '600px',  // Limit max width for better look on large screens
                                margin: 'auto'  // Helps in further ensuring center alignment
                            },
                        }}
                    >
                        <div className="bg-white rounded-lg p-8">
                            <h1 className="text-5xl font-bold text-center text-gray-800 mb-6">Congratulations!</h1>
                            <p className="text-lg text-center text-gray-600 mb-4">You completed the game in {turns} turns!</p>
                            <div className="flex flex-col items-center">
                                <input
                                    className="my-4 p-2 border-2 border-gray-300 rounded w-full text-center text-lg"
                                    placeholder="Enter your name"
                                    value={playerName}
                                    onChange={(e) => setPlayerName(e.target.value)}
                                />
                                <button
                                    className="text-white bg-blue-500 hover:bg-blue-600 font-bold py-2 px-4 rounded-full transition duration-300"
                                    onClick={addToLeaderBoard}
                                >
                                    Add to Leaderboard
                                </button>
                                <button
                                    className="mt-4 text-white bg-gray-500 hover:bg-gray-600 font-bold py-2 px-4 rounded-full transition duration-300"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal>
                </>
            )}
            <button
                onClick={() => navigate(-1)} // Updated method for going back
                className="flex flex-col items-center px-4 py-2 text-gray-800 rounded transition duration-300 ease-in-out mt-4 mb-8"
            >
                <FontAwesomeIcon style={{ color: "#fff", scale: "1.5" }} icon={faPersonWalkingArrowLoopLeft} />
                <span className="go-back ml-2 text-white">Go Back</span> {/* Optional text */}
            </button>
            <div className="game">
                <h1 className="link-shortener-title">Memory Game</h1>
                <p className="link-shortener-subtitle">Turns: {turns}</p>
                <button onClick={shuffleCards} className="new-game-button">New Game</button>
                <button
                    onClick={toggleLeaderboard}
                    className="new-game-button bg-yellow-500 hover:bg-yellow-600 text-white font-semibold ml-4 rounded-lg shadow transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50"
                >
                    <FontAwesomeIcon icon={faTrophy} className="text-lg" />
                </button>

                <div className="card-grid">
                    {cards.map(card => (
                        <div className={`card ${card === choiceOne || card === choiceTwo || card.matched ? 'flipped' : ''}`}
                            key={card.id} onClick={() => !disabled && handleChoice(card)}>
                            <div className="front">?</div>
                            <div className="back" style={{ backgroundImage: `url(${card.name})` }}></div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MemoryGame;

import React from 'react';
import javaImage from '../resources/skills_images/java.png';
import htmlImage from '../resources/skills_images/html.png';
import cssImage from '../resources/skills_images/css.png';
import jsImage from '../resources/skills_images/javascript.png';
import reactImage from '../resources/skills_images/react.png';
import gitImage from '../resources/skills_images/git.png';
import sqlImage from '../resources/skills_images/sql.png';
import mongodbImage from '../resources/skills_images/mongodb.png';
import { useState } from 'react';
import { useTheme } from '../components/ThemeContext';

const Skills = () => {
    const { darkMode, onChange } = useTheme(); // Use the context
    const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 });

    const handleMouseEnter = (event, skillName, description) => {
        const { pageX, pageY } = event;
        setTooltip({
            show: true,
            content: `${skillName} - ${description}`, // Include the description
            x: pageX + 10,
            y: pageY + 10
        });
    };

    const handleMouseLeave = () => {
        setTooltip({ show: false, content: '', x: 0, y: 0 });
    };

    const skills = [
        { id: 'java', name: 'Java', imageUrl: javaImage, superSkill: true, description: 'Expert' },
        { id: 'html', name: 'HTML', imageUrl: htmlImage, superSkill: false, description: 'Proficient' },
        { id: 'css', name: 'CSS', imageUrl: cssImage, superSkill: false, description: 'Proficient' },
        { id: 'javascript', name: 'JavaScript', imageUrl: jsImage, superSkill: false, description: 'Intermediate' },
        { id: 'react', name: 'React', imageUrl: reactImage, superSkill: true, description: 'Expert' },
        { id: 'git', name: 'Git', imageUrl: gitImage, superSkill: false, description: 'Proficient' },
        { id: 'sql', name: 'SQL', imageUrl: sqlImage, superSkill: true, description: 'Expert' },
        { id: 'mongodb', name: 'MongoDB', imageUrl: mongodbImage, superSkill: false, description: 'Intermediate' },
    ];

    return (
        <div className="my-8 mx-2 skills-section">
            {skills.map(skill => (
                <div key={skill.id}
                    className={`mx-2 skill-icon ${skill.superSkill ? 'super-skill' : ''}`}
                    onMouseEnter={(e) => handleMouseEnter(e, skill.name, skill.description)}
                    onMouseLeave={handleMouseLeave}
                    data-skill={skill.name}>
                    <img src={skill.imageUrl} alt={skill.name} className="skill-image" style={{ height: 50 }} />
                </div>
            ))}
            {tooltip.show && (
                <div
                    id="skill-tooltip"
                    style={{
                        color: 'black',
                        position: 'absolute',
                        top: tooltip.y,
                        left: tooltip.x,
                        backgroundColor: 'white',
                        padding: '10px 15px',
                        borderRadius: '8px',
                        boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
                        zIndex: 100, // Ensure the tooltip is above other content
                        font: "bold 16px/1.5 'SF Pro Display', 'SF Pro Text', 'Helvetica Neue', Arial, sans-serif", // Apple-like bold font
                    }}>
                    {tooltip.content}
                </div>
            )}
        </div>
    );
}

export default Skills;
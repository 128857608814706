import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import '../../resources/css/DownloadButton.css'; // Import CSS

function DownloadButton({ darkMode, url }) {
    const [downloadState, setDownloadState] = useState('initial'); // initial, downloading, complete
    const [progress, setProgress] = useState(0);

    const handleDownload = () => {
        setDownloadState('downloading');
    
        // Start timing the download
        const downloadStartedAt = Date.now();
    
        axios.get(url, {
            responseType: 'blob', // Important for allowing the file download to be triggered
            onDownloadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            },
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '/resume/TomasCanavilhasResume.pdf'); // Provide the file name here
            document.body.appendChild(link);
            link.click();
    
            // Calculate how long the download has taken so far
            const downloadDuration = Date.now() - downloadStartedAt;
    
            // Ensure the loading animation is displayed for a minimum of 2 seconds
            const additionalDelay = Math.max(0, 1000 - downloadDuration);
    
            setTimeout(() => {
                setDownloadState('complete');
                setTimeout(() => {
                    setDownloadState('initial');
                    setProgress(0);
                }, 1000); // Additional 1 second to view the 'complete' state
            }, additionalDelay);
        })
        .catch(error => {
            console.error('Download failed: ', error);
            // Handle error
            setDownloadState('initial');
            setProgress(0);
        });
    };    

    return (
        <button className={`download-button ${downloadState} ${darkMode ? 'dark-mode' : ''}`} onClick={handleDownload}>
            {downloadState === 'initial' && <FontAwesomeIcon icon={faDownload} />}
            {downloadState === 'initial' && <span>Download Resume</span>}
            {downloadState === 'downloading' && (
                <>
                    <Spin />
                    <div className="progress-bar" style={{ width: `${progress}%` }} color='white'></div>
                    <span>{`${progress}%`}</span>
                </>
            )}
            {downloadState === 'complete' && <FontAwesomeIcon icon={faCheck} />}
            {downloadState === 'complete' && <span>Done!</span>}
        </button>
    );
}

export default DownloadButton;

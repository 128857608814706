import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutMePage from './pages/AboutMePage';
import Projects from './pages/Projects';
import Background from './components/Background';
import { ThemeProvider, useTheme } from './components/ThemeContext'; // Corrected import
import 'bootstrap/dist/css/bootstrap.min.css';
import LinkShortener from "./pages/LinkShortener";
import './resources/css/About.css';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Switch } from 'antd';
import PasswordGenerator from './pages/PasswordGenerator';
import MemoryGame from './pages/MemoryGame';

function App() {
    const { darkMode, onChange } = useTheme(); // Correctly use useTheme

    document.title = "Tomás Canavilhas - Portfolio";

    useEffect(() => {
        const isHttps = window.location.protocol === 'https:';
        if (!isHttps) {
            window.location.href = window.location.href.replace('http://', 'https://');
        }
    }, []);

    return (
        <Router className="p-0 m-0 overflow-x-hidden">
            <Background />
            <Space direction="vertical" className="p-8">
                <Switch
                    onChange={onChange} // Use onChange from context
                    unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
                    checkedChildren={<FontAwesomeIcon icon={faMoon} />}
                    checked={darkMode} // Controlled component with darkMode from context
                />
            </Space>
            <div className="">
                <Routes>
                    <Route path="/" element={<AboutMePage />} />
                    <Route path="/linkshortener" element={<LinkShortener />} />
                    <Route path="/passwordgenerator" element={<PasswordGenerator />} />
                    <Route path="/memorygame" element={<MemoryGame />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
